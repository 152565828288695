import React, { useLayoutEffect } from 'react';
import './ultimos-sonhos.component.scss';
import { Box, Button, SwipeableDrawer } from '@mui/material';
import SonhosCelebresModel from '../../models/sonhos-celebres.model';
import { GetSonhosCelebresService, GetUltimosSonhosService } from '../../services/pages.service';
import { SnackService } from '../../services/snack.service';
import { useHistory } from 'react-router-dom';
import SonhosModel from '../../models/sonhos.model';
import ComentaiosComponent from '../comentarios/comentarios.component';

function UltimosSonhosComponent() {

  const [listSonhos, setListSonhos] = React.useState<Array<SonhosModel>>([]);
  const [sonhoDrawer, setSonhoDrawer] = React.useState<SonhosModel>();
  const [openDrawer, setOpenDrawer] = React.useState<boolean>(false);
  let history = useHistory();

  useLayoutEffect(() => {
    setTimeout(() => {
      GetSonhos();
    }, 500);
  }, []);

  const GetSonhos = () => {
    GetUltimosSonhosService({ user_id: '9', idsSonhos: [] }).then(res => {
      if (res.data.error) {
        SnackService.sendMSG(res.data.error);
      } else {
        setListSonhos(listSonhos.concat(res.data.sonhosArr));
      }
    }).catch(err => {
      //SnackService.sendMSG('Erro com a conexão ao consultar sonhos na base de dados.');
      console.log(err);
    });
  }

  const GoPage = (page: string) => {
    history?.push({
      pathname: `/${page}`
    })
  }

  return (
    <>
      <div className="box-ultimos-sonhos">
        <div className="titulo flex flex-sp flex-cv">
          <h3>Últimos sonhos</h3>
          <Button className='link' onClick={() => GoPage('ultimos-sonhos?app=1')}>Ver todos</Button>
        </div>
        <div className="cards-sonhos flex flex-ch">
          {
            listSonhos ? listSonhos.map((item, indexCurso) => (
              <div onClick={() => {
                setSonhoDrawer(item);
                setOpenDrawer(true);
              }} className="card-sonhos flex" key={indexCurso}>
                <div className="txt">
                  <strong>Sonho <small>({item.data})</small>:</strong> {item.content.substring(0, 70)} {item.content.length >= 70 && '...'}
                </div>
              </div>
            )) : <span>Nenhum item encontrado.</span>
          }
        </div>
      </div>
      <SwipeableDrawer
        anchor='bottom'
        open={openDrawer}
        onClose={() => { setOpenDrawer(false) }}
        onOpen={() => { setOpenDrawer(true) }}
        className='sonhos-drawer'
      >
        <Box
          sx={{ height: '90vh', width: '100%' }}
          role="presentation"
        // onClick={() => { setOpenDrawer(false) }}
        //onKeyDown={() => { setOpenDrawer(false) }}
        >
          {
            sonhoDrawer &&
            <>
              <h2>Sonho ({sonhoDrawer.data}):</h2>
              <div className="desc">
                {sonhoDrawer?.content}
              </div>
              <h3>Interpretação:</h3>
              <div className="desc">
                {sonhoDrawer?.interpretacao}
              </div>
              <div className="box-comentarios">
                <ComentaiosComponent idPost={sonhoDrawer.id} palavrasModeradasADM={['bobo', 'bobão']} />
              </div>
            </>
          }
        </Box>
      </SwipeableDrawer>
    </>
  );
}

export default UltimosSonhosComponent;
