import React, { useLayoutEffect, useRef } from 'react';
import './diarios.page.scss';
import { LoadingService } from '../../services/loading.service';
import { SnackService } from '../../services/snack.service';
import DiariosModel from '../../models/diarios.model';
import { useHistory } from 'react-router-dom';
import { Box, Button, Modal, TextField, Tooltip } from '@mui/material';
import { AtualizaDiarioService, BuscaSonhosService, ListDiariosService, ListSonhosService, RelacionarService } from '../../services/eventos.service';
import AddIcon from '@mui/icons-material/Add';
import UsuarioModel from '../../models/usuario.model';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import FlareIcon from '@mui/icons-material/Flare';
import EditIcon from '@mui/icons-material/Edit';
import { getAuthUser } from '../../services/auth';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import { useInput } from '../../hooks/useInput.hook';
import SonhosModel from '../../models/sonhos.model';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

function DiariosPage() {

  const [listIDs, setListIDs] = React.useState<Array<number>>([]);
  const [listDiarios, setListDiarios] = React.useState<Array<DiariosModel>>([]);
  const [acabouListDiarios, setAcabouListDiarios] = React.useState<boolean>(false);
  const [usuarioLogado, setUsuarioLogado] = React.useState<UsuarioModel>(getAuthUser());
  const [idDiarioEditando, setIdDiarioEditando] = React.useState(0);
  const [openModal, setOpenModal] = React.useState(false);
  const [openModalRelacionamento, setOpenModalRelacionamento] = React.useState(false);
  const [valueDate, setValueDate] = React.useState<Dayjs | null>(null);
  const { value: buscaInputText, bind: bindBuscaInputText, setValue: setBuscaInputText } = useInput('');
  const [listSonhosIDs, setListSonhosIDs] = React.useState<Array<number>>([]);
  const [listSonhos, setListSonhos] = React.useState<Array<SonhosModel>>([]);
  const [acabouListSonhos, setAcabouListSonhos] = React.useState<boolean>(false);
  const [itemDiarioModalDiario, setitemDiarioModalDiario] = React.useState<DiariosModel>();
  const [idModalDiario, setIdModalDiario] = React.useState<number>(0);
  const today = dayjs();
  const ref = useRef<any>();
  let history = useHistory();

  useLayoutEffect(() => {
    setTimeout(() => {
      GetDiarios();
    }, 500);
  }, []);

  useLayoutEffect(() => {

  }, [idModalDiario]);

  const GetDiarios = () => {
    LoadingService.sendLoadingAtivo(true);
    ListDiariosService({ user_id: usuarioLogado.ID, idsDiarios: listIDs }).then(res => {
      LoadingService.sendLoadingAtivo(false);
      if (res.data.error) {
        SnackService.sendMSG(res.data.error);
      } else {
        setListIDs(listIDs.concat(res.data.idsArr));
        setListDiarios(listDiarios.concat(res.data.diariosArr));
        if (res.data.diariosArr.length < 3) {
          setAcabouListDiarios(true);
        }
      }
    }).catch(err => {
      //SnackService.sendMSG('Erro com a conexão ao consultar diarios na base de dados.');
      LoadingService.sendLoadingAtivo(false);
      console.log(err);
    });
  }

  const getscroll = (refPage: any) => {
    if (refPage.target.scrollTop > 5) {
      document.body.classList.add('is-scroll');
    } else {
      document.body.classList.remove('is-scroll');
    }
  };

  const AtualizaDatePost = () => {
    let postDate = valueDate ? valueDate['$y'] + '-' + (valueDate['$M'] + 1) + '-' + valueDate['$D'] + ' 00:00:00' : '0000-00-00 00:00:00';
    let itemNewDate = valueDate ? (valueDate['$D'] <= 9 ? '0' + valueDate['$D'] : valueDate['$D']) + '/' + (valueDate['$M'] + 1) + '/' + valueDate['$y'] : '00/00/0000';
    LoadingService.sendLoadingAtivo(true);
    AtualizaDiarioService({ post_id_us: idDiarioEditando, post_date: postDate }).then(res => {
      LoadingService.sendLoadingAtivo(false);
      if (res.data.error) {
        SnackService.sendMSG(res.data.error);
      } else {
        setOpenModal(false);
        const arrTemp = [...listDiarios];
        const item = arrTemp.find(x => x.id === idDiarioEditando);
        if (item) {
          item.data = itemNewDate;
          setListDiarios(arrTemp);
        }
      }
    }).catch(err => {
      //SnackService.sendMSG('Erro com a conexão ao consultar sonhos na base de dados.');
      LoadingService.sendLoadingAtivo(false);
      console.log(err);
    });
  };

  const GetSonhos = () => {
    LoadingService.sendLoadingAtivo(true);
    BuscaSonhosService({ user_id: usuarioLogado.ID, termo: buscaInputText }).then(res => {
      LoadingService.sendLoadingAtivo(false);
      if (res.data.error) {
        SnackService.sendMSG(res.data.error);
      } else {
        setListSonhos(res.data.sonhosArr);
      }
    }).catch(err => {
      //SnackService.sendMSG('Erro com a conexão ao consultar sonhos na base de dados.');
      LoadingService.sendLoadingAtivo(false);
      console.log(err);
    });
  }

  const Relacionar = (dto: {
    sonho: string,
    data_sonho: string,
    data_atualizacao_sonho_relacionado: string,
    sonho_id: number
  }) => {
    LoadingService.sendLoadingAtivo(true);
    let tempIDModal = dto.sonho === '' ? dto.sonho_id : idModalDiario;

    RelacionarService({
      post_id: tempIDModal,
      sonho_id: dto.sonho_id,
      data_sonho_relacionado: dto.data_sonho,
      data_atualizacao_sonho_relacionado: dto.data_atualizacao_sonho_relacionado,
      sonho_relacionado: dto.sonho
    }).then(res => {
      LoadingService.sendLoadingAtivo(false);
      if (res.data.error) {
        SnackService.sendMSG(res.data.error);
      } else {
        //setListSonhos(res.data.sonhosArr);
        setOpenModalRelacionamento(false);
        const arrTemp = [...listDiarios];
        const item = arrTemp.find(x => x.id === tempIDModal);
        if (item) {
          item.data_sonho_relacionado = dto.data_sonho;
          item.sonho_relacionado = dto.sonho;
          setListDiarios(arrTemp);
        }
      }
    }).catch(err => {
      //SnackService.sendMSG('Erro com a conexão ao consultar sonhos na base de dados.');
      LoadingService.sendLoadingAtivo(false);
      console.log(err);
    });
  }

  const datediff = (first, second) => {
    return Math.round((second - first) / (1000 * 60 * 60 * 24));
  }

  const parseDate = (str) => {
    var mdy = str.split('/');
    return new Date(mdy[2], mdy[1] - 1, mdy[0]);
  }

  const removerRelacao = (idModal: number) => {
    Relacionar({ sonho: '', data_sonho: '', data_atualizacao_sonho_relacionado: '', sonho_id: idModal });
  }

  return (
    <>
      <section className="page diarios" ref={ref} onScroll={(scroll) => getscroll(scroll)}>
        <div className="head">
          <div className="container">
            <h1 className='flex flex-cv flex-sp'>
              <div className="esq flex flex-cv">
                <Button className='bt-seta' onClick={() => { history.goBack() }}>
                  <ArrowBackIosIcon />
                </Button>
                Lista diário
              </div>
            </h1>
          </div>
        </div>
        <div className="container">
          <div className="cards-diarios flex flex-ch flex-wr">
            {
              listDiarios ? listDiarios.map((item, indexDiario) => (
                <div
                  className="card-diarios"
                  key={indexDiario}
                >
                  {
                    item.data_sonho_relacionado &&
                    <>
                      <div className='box-sonho'>
                        <h2 className='flex flex-sp'>
                          <strong>Sonho:</strong>
                          {item.data_sonho_relacionado}
                        </h2>
                        <div className="descricao" dangerouslySetInnerHTML={{ __html: item.sonho_relacionado ? item.sonho_relacionado : '' }}></div>
                      </div>
                      <h3>Após <strong>{datediff(parseDate(item.data_sonho_relacionado), parseDate(item.data))}</strong> dias, isso aconteceu:</h3>
                    </>
                  }
                  <h2>
                    <Button onClick={() => {
                      setIdDiarioEditando(item.id);
                      setOpenModal(true);
                    }}>
                      <EditIcon />
                    </Button>
                    {item.data}
                  </h2>
                  <div className="descricao" dangerouslySetInnerHTML={{ __html: item.content ? item.content : '' }}></div>
                  <div className="bts flex flex-ri">
                    {
                      item.data_sonho_relacionado ?
                        <Button className='bt remove' onClick={() => {
                          removerRelacao(item.id);
                        }}>
                          <RemoveCircleIcon /> Remover relação
                        </Button>
                        :
                        <Button className='bt' onClick={() => {
                          setitemDiarioModalDiario(item);
                          setIdModalDiario(item.id);
                          setOpenModalRelacionamento(true);
                        }}>
                          <FlareIcon /> Relacionar com sonho
                        </Button>
                    }
                  </div>
                </div>
              )) : <span>Nenhum item encontrado.</span>
            }
          </div>
          {
            (!acabouListDiarios && listDiarios.length > 1) &&
            <div className="box-bt-mais flex flex-ch">
              <Button className='bt' onClick={GetDiarios}>
                <AddIcon />
                itens...
              </Button>
            </div>
          }
        </div>
      </section>
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className='modal-edit-date'
      >
        <Box>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'pt-br'}>
            <DatePicker
              format="DD/MM/YYYY"
              value={valueDate}
              onChange={setValueDate}
              maxDate={today}
            />
          </LocalizationProvider>
          <div className="flex flex-ch">
            <Button className='bt' onClick={AtualizaDatePost}>Salvar</Button>
          </div>
        </Box>
      </Modal>
      <Modal
        open={openModalRelacionamento}
        onClose={() => setOpenModalRelacionamento(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className='modal-buscar'
      >
        <Box>
          <p>
            Este acontecimento tem uma forte relação com algum sonho que você teve?
          </p>
          <div className="txt-acontecimento">
            <strong>{itemDiarioModalDiario?.data}</strong>
            <div dangerouslySetInnerHTML={{ __html: itemDiarioModalDiario ? itemDiarioModalDiario?.content : '' }}></div>
          </div>
          <TextField {...bindBuscaInputText} label="Digite o sonho aqui" variant="standard" />
          <div className="flex flex-ch">
            <Button className='bt' onClick={GetSonhos}>Buscar</Button>
          </div>
          <div className="lista-sonhos">
            <div className="cards-sonhos flex flex-ch flex-wr">
              {
                listSonhos ? listSonhos.map((item, indexDiario) => (
                  <div
                    className="card-sonhos"
                    key={indexDiario}
                  >
                    <h2>
                      {item.data}
                    </h2>
                    <div className="descricao">
                      <div className="pergunta">
                        <strong>Sonho:</strong> {item.content}
                      </div>
                    </div>
                    <div className="bts flex flex-ri">
                      <Button className='bt' onClick={() => Relacionar({
                        sonho: item.content,
                        data_sonho: item.data,
                        data_atualizacao_sonho_relacionado: item.modified_date,
                        sonho_id: item.id
                      })}>
                        <FlareIcon /> Relacionar!
                      </Button>
                    </div>
                  </div>
                )) : <span>Nenhum item encontrado.</span>
              }
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default DiariosPage;
