import React, { useLayoutEffect } from 'react';
import './bottom-navigation.component.scss';
import { BottomNavigation, BottomNavigationAction } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import HandshakeIcon from '@mui/icons-material/Handshake';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ChatIcon from '@mui/icons-material/Chat';
import NightsStayIcon from '@mui/icons-material/NightsStay';
import Brightness5Icon from '@mui/icons-material/Brightness5';
import StarIcon from '@mui/icons-material/Star';
import PublicIcon from '@mui/icons-material/Public';
import { useHistory, useLocation } from 'react-router-dom';

function BottomNavigationComponent() {
  const [value, setValue] = React.useState(0);

  let history = useHistory();
  let location = useLocation();

  useLayoutEffect(() => {
    if (window.location.href.indexOf('sonho') > -1) {
      setValue(0);
    } else if (window.location.href.indexOf('home') > -1) {
      setValue(1);
    } else if (window.location.href.indexOf('aconteceu') > -1) {
      setValue(2);
    } else if (window.location.href.indexOf('lojas') > -1) {
      setValue(3);
    } else if (window.location.href.indexOf('concierge') > -1) {
      setValue(4);
    } else {
      setValue(10);
    }
  }, [location]);

  const GoPage = (page: string) => {
    history?.push({
      pathname: '/' + page
    })
  }

  return (
    <div className="menu-foot flex flex-ch">
      <BottomNavigation
        showLabels
        value={value}
        onChange={(event, newValue) => {
          //setValue(newValue);
        }}
      >
        <BottomNavigationAction className='lua' label="Sonho" icon={<NightsStayIcon />} onClick={() => { GoPage('sonho?app=1') }} />
        {/* <BottomNavigationAction label="Eventos" icon={<CalendarMonthIcon />} onClick={() => { GoPage('eventos') }} /> */}
        <BottomNavigationAction label="Home" icon={<PublicIcon />} onClick={() => { GoPage('home?app=1') }} />
        {/* <BottomNavigationAction label="Lojas" icon={<LocationOnIcon />} onClick={() => { GoPage('lojas') }} /> */}
        <BottomNavigationAction className='sol' label="Diário" icon={<Brightness5Icon />} onClick={() => { GoPage('aconteceu?app=1') }} />
      </BottomNavigation>
    </div>
  );
}

export default BottomNavigationComponent;
