import React, { useLayoutEffect, useRef } from 'react';
import './sonhos.page.scss';
import { LoadingService } from '../../services/loading.service';
import { SnackService } from '../../services/snack.service';
import { useHistory } from 'react-router-dom';
import { Box, Button, Modal, Tooltip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import UsuarioModel from '../../models/usuario.model';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { getAuthUser } from '../../services/auth';
import { AtualizaSonhosService, ListSonhosService } from '../../services/eventos.service';
import SonhosModel from '../../models/sonhos.model';
import InfoIcon from '@mui/icons-material/Info';
import EditIcon from '@mui/icons-material/Edit';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import CancelIcon from '@mui/icons-material/Cancel';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/pt-br';


function SonhosPage() {

  const [listIDs, setListIDs] = React.useState<Array<number>>([]);
  const [listSonhos, setListSonhos] = React.useState<Array<SonhosModel>>([]);
  const [acabouListSonhos, setAcabouListSonhos] = React.useState<boolean>(false);
  const [usuarioLogado, setUsuarioLogado] = React.useState<UsuarioModel>(getAuthUser());
  const [openTooltip, setOpenTooltip] = React.useState<any>(null);
  const [openModal, setOpenModal] = React.useState(false);
  const [idSonhoEditando, setIdSonhoEditando] = React.useState(0);
  const [idSonhoSystemEditando, setIdSonhoSystemEditando] = React.useState(0);
  const [valueDate, setValueDate] = React.useState<Dayjs | null>(null);
  const [showMaisTXT, setShowMaisTXT] = React.useState<any>(null);
  const today = dayjs();
  const ref = useRef<any>();
  let history = useHistory();

  dayjs().locale('pt-br').format();

  useLayoutEffect(() => {
    setTimeout(() => {
      GetSonhos();
    }, 500);
  }, []);

  const GetSonhos = () => {
    LoadingService.sendLoadingAtivo(true);
    ListSonhosService({ user_id: usuarioLogado.ID, idsSonhos: listIDs }).then(res => {
      LoadingService.sendLoadingAtivo(false);
      if (res.data.error) {
        SnackService.sendMSG(res.data.error);
      } else {
        setListIDs(listIDs.concat(res.data.idsArr));
        setListSonhos(listSonhos.concat(res.data.sonhosArr));
        if (res.data.sonhosArr.length < 4) {
          setAcabouListSonhos(true);
        }
      }
    }).catch(err => {
      //SnackService.sendMSG('Erro com a conexão ao consultar sonhos na base de dados.');
      LoadingService.sendLoadingAtivo(false);
      console.log(err);
    });
  }

  const getscroll = (refPage: any) => {
    if (refPage.target.scrollTop > 5) {
      document.body.classList.add('is-scroll');
    } else {
      document.body.classList.remove('is-scroll');
    }
  };

  const AtualizaDatePosts = () => {
    let postDate = valueDate ? valueDate['$y'] + '-' + (valueDate['$M'] + 1) + '-' + valueDate['$D'] + ' 00:00:00' : '0000-00-00 00:00:00';
    let itemNewDate = valueDate ? (valueDate['$D'] <= 9 ? '0' + valueDate['$D'] : valueDate['$D']) + '/' + (valueDate['$M'] + 1) + '/' + valueDate['$y'] : '00/00/0000';
    LoadingService.sendLoadingAtivo(true);
    AtualizaSonhosService({ post_id_us: idSonhoEditando, post_date: postDate }).then(res => {
      LoadingService.sendLoadingAtivo(false);
      if (res.data.error) {
        SnackService.sendMSG(res.data.error);
      } else {
        setOpenModal(false);
        const arrTemp = [...listSonhos];
        const item = arrTemp.find(x => x.id === idSonhoEditando);
        if (item) {
          item.data = itemNewDate;
          setListSonhos(arrTemp);
        }
      }
    }).catch(err => {
      //SnackService.sendMSG('Erro com a conexão ao consultar sonhos na base de dados.');
      LoadingService.sendLoadingAtivo(false);
      console.log(err);
    });
  };

  return (
    <>
      <section className="page sonhos" ref={ref} onScroll={(scroll) => getscroll(scroll)}>
        <div className="head">
          <div className="container">
            <h1 className='flex flex-cv flex-sp'>
              <div className="esq flex flex-cv">
                <Button className='bt-seta' onClick={() => { history.goBack() }}>
                  <ArrowBackIosIcon />
                </Button>
                Lista sonhos
              </div>
            </h1>
          </div>
        </div>
        <div className="container">
          <div className="cards-sonhos flex flex-ch flex-wr">
            {
              listSonhos ? listSonhos.map((item, indexSonho) => (
                <div
                  className="card-sonhos"
                  key={indexSonho}
                >
                  <h2>
                    <Button onClick={() => {
                      setIdSonhoEditando(item.id);
                      setOpenModal(true);
                    }}>
                      <EditIcon />
                    </Button>
                    {item.data} <br />
                    {
                      (item.modified_date !== item.data) &&
                      <>
                        <Tooltip
                          PopperProps={{
                            disablePortal: true,
                          }}
                          placement="top"
                          onClose={() => setOpenTooltip(null)}
                          open={openTooltip === indexSonho ? true : false}
                          disableFocusListener
                          disableHoverListener
                          disableTouchListener
                          title="Esse sonho não foi registrado na data em que ocorreu, pois a data foi inserida manualmente. Quando os sonhos são inseridos manualmente, há o risco de perderem credibilidade, uma vez que os usuários podem inadvertidamente relacioná-los a eventos significativos ocorridos após o sonho. Por exemplo, se algo marcante, como a queda de um meteoro em uma cidade do interior que resultou na morte de três vacas, aconteceu e o usuário decide inserir um sonho três semanas anteriores mencionando o meteoro, isso pode comprometer a veracidade do registro. Portanto, é fundamental lembrar-se de registrar seus sonhos na mesma data em que ocorreram, pois isso contribui significativamente para a credibilidade das experiências relatadas."
                        >
                          <Button className='bt-info' onClick={() => {
                            openTooltip === indexSonho ?
                              setOpenTooltip(null)
                              :
                              setOpenTooltip(indexSonho)
                          }}>
                            {
                              openTooltip === indexSonho ?
                                <CancelIcon />
                                :
                                <InfoIcon />
                            }
                          </Button>
                        </Tooltip>
                        <small>
                          {item.modified_date} (Data atualização)
                        </small>
                      </>
                    }
                  </h2>
                  <div className={"descricao " + (showMaisTXT === indexSonho ? 'show' : '')}>
                    <div className="pergunta">
                      <strong>Sonho:</strong> {item.content}
                    </div>
                    <div className={"resposta"}>
                      <strong>Interpretação:</strong> {item.interpretacao}
                      <div className="box-bt-mais">
                        {
                          showMaisTXT === indexSonho ?
                            <Button className='recolher' onClick={() => { setShowMaisTXT(null) }}>Recolher</Button>
                            :
                            <Button className='ler-mais' onClick={() => { setShowMaisTXT(indexSonho) }}>Ler mais</Button>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              )) : <span>Nenhum item encontrado.</span>
            }
          </div>
          {
            (!acabouListSonhos && listSonhos.length >= 4) &&
            <div className="box-bt-mais flex flex-ch">
              <Button className='bt' onClick={GetSonhos}>
                <AddIcon />
                itens...
              </Button>
            </div>
          }
        </div>
      </section>
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className='modal-edit-date'
      >
        <Box>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'pt-br'}>
            <DatePicker
              format="DD/MM/YYYY"
              value={valueDate}
              onChange={setValueDate}
              maxDate={today}
            />
          </LocalizationProvider>
          <div className="flex flex-ch">
            <Button className='bt' onClick={AtualizaDatePosts}>Salvar</Button>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default SonhosPage;
