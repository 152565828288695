import React from 'react';
import { Button, CircularProgress, InputAdornment, TextField } from '@mui/material';
import './contato.page.scss';
import UsuarioModel from '../../models/usuario.model';
import { SnackService } from '../../services/snack.service';
import { LoadingService } from '../../services/loading.service';
import { CadastrarService, ContatoService } from '../../services/api-usuario.service';
import { useInput } from '../../hooks/useInput.hook';
import { AccountCircle } from '@mui/icons-material';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import Box from '@mui/material/Box';
import { getAuthUser } from '../../services/auth';


function ContatoPage() {

  const { value: mensagemInputText, bind: bindMensagemInputText, setValue: setMensagemInputText } = useInput('');
  const [usuarioLogado, setUsuarioLogado] = React.useState<UsuarioModel>(getAuthUser());
  const [showLoading, setShowLoading] = React.useState<boolean>(false);

  var dto: any;

  const Cadastrar = (e: any) => {
    e.stopPropagation();

    if (!mensagemInputText) {
      SnackService.sendMSG('O campo mensagem é obrigatório');
      return;
    }

    CadastrarAposVerificarMensagem();

  }

  const CadastrarAposVerificarMensagem = () => {
    dto = {
      user_mensagem: mensagemInputText,
      user_email: usuarioLogado.user_email,
      user_name: usuarioLogado.first_name,
      user_id: usuarioLogado.ID
    };

    LoadingService.sendLoadingAtivo(true);

    ContatoService(dto).then((res) => {
      LoadingService.sendLoadingAtivo(false);
      if (res.data?.error) {
        SnackService.sendMSG(res.data?.error);
      } else {
        if (res?.data?.contato) {
          SnackService.sendMSG(res?.data?.contato);
          LimparForm();
          setShowLoading(false);
        }
      }
    }).catch(err => {
      LoadingService.sendLoadingAtivo(false);
      SnackService.sendMSG('Erro com a conexão.');
      console.log(err);
    });
  }

  const LimparForm = () => {
    setMensagemInputText('');
  }

  return (
    <div className="contato page">
      <div className="container">
        <h1>
          Entre em contato e deixe uma dúvida, sugestão ou mensagem qualquer
        </h1>
        <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
          <MailOutlineIcon />
          <TextField {...bindMensagemInputText} multiline maxRows={4} id="outlined-multiline-static" label="Mensagem" variant="standard" />
        </Box>
        <Button onClick={Cadastrar} className="bt">
          Enviar
        </Button>
      </div>
    </div>
  );
}

export default ContatoPage;
