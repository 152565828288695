import React, { useLayoutEffect } from 'react';
import './sonhos-realizados.page.scss';
import { LoadingService } from '../../services/loading.service';
import { GetEventosService } from '../../services/eventos.service';
import { SnackService } from '../../services/snack.service';
import EventosModel from '../../models/eventos.model';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { useHistory } from 'react-router-dom';
import { Box, Button, SwipeableDrawer } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { ListRelacionadosService } from '../../services/pages.service';
import SonhosRealizadosModel from '../../models/sonhos-realizados.model';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';

function SonhosRealizadosPage() {

  const [listIDs, setListIDs] = React.useState<Array<number>>([]);
  const [listSonhosRealizados, setListSonhosRealizados] = React.useState<Array<SonhosRealizadosModel>>([]);
  const [acabouListSonhosRealizados, setAcabouListSonhosRealizados] = React.useState<boolean>(false);
  const [openDrawer, setOpenDrawer] = React.useState<boolean>(false);
  const [sonhoDrawer, setSonhoDrawer] = React.useState<SonhosRealizadosModel>();
  let history = useHistory();

  useLayoutEffect(() => {
    setTimeout(() => {
      GetSonhosRealizados();
    }, 500);
  }, []);

  const GetSonhosRealizados = () => {
    LoadingService.sendLoadingAtivo(true);
    ListRelacionadosService({ user_id: '7', idsDiarios: listIDs }).then(res => {
      LoadingService.sendLoadingAtivo(false);
      if (res.data.error) {
        SnackService.sendMSG(res.data.error);
      } else {
        setListIDs(listIDs.concat(res.data.idsArr));
        setListSonhosRealizados(listSonhosRealizados.concat(res.data.diariosArr));
        if (res.data.diariosArr.length < 5) {
          setAcabouListSonhosRealizados(true);
        }
      }
    }).catch(err => {
      //SnackService.sendMSG('Erro com a conexão ao consultar eventos na base de dados.');
      LoadingService.sendLoadingAtivo(false);
      console.log(err);
    });
  }

  return (
    <>
      <section className="page sonhos-realizados">
        <div className="head">
          <div className="container">
            <div className="flex flex-cv flex-sp">

            </div>
          </div>
        </div>
        <div className="container">
          <div className="cards-sonhos-realizados flex flex-ch flex-wr">
            {
              listSonhosRealizados ? listSonhosRealizados.map((item, indexCurso) => (
                <div onClick={() => {
                  setSonhoDrawer(item);
                  setOpenDrawer(true);
                }} className="card-sonhos-realizados flex" key={indexCurso}>
                  {
                    item.imagem_sonho &&
                    <div className="img" style={{ backgroundImage: 'url(' + item.imagem_sonho + ')' }}></div>
                  }
                  <div className="box-ico">
                    <div className="txt">
                      <h4>Sonho: {item.data_sonho_relacionado}</h4>
                      <AllInclusiveIcon />
                      <h4>Diário: {item.data}</h4>
                    </div>
                  </div>
                  {
                    item.imagem_diario &&
                    <div className="img" style={{ backgroundImage: 'url(' + item.imagem_diario + ')' }}></div>
                  }
                </div>
              )) : <span>Nenhum item encontrado.</span>
            }
          </div>
          {
            (!acabouListSonhosRealizados && listSonhosRealizados.length > 5) &&
            <div className="box-bt-mais flex flex-ch">
              <Button className='bt cinza' onClick={GetSonhosRealizados}>
                <AddIcon />
                sonhos...
              </Button>
            </div>
          }
        </div>
      </section>
      <SwipeableDrawer
        anchor='bottom'
        open={openDrawer}
        onClose={() => { setOpenDrawer(false) }}
        onOpen={() => { setOpenDrawer(true) }}
        className='sonhos-drawer'
      >
        <Box
          sx={{ height: '90vh', width: '100%' }}
          role="presentation"
        // onClick={() => { setOpenDrawer(false) }}
        //onKeyDown={() => { setOpenDrawer(false) }}
        >
          <img src={sonhoDrawer?.imagem_sonho} alt='Sonho' />
          <h2>Sonho ({sonhoDrawer?.data_sonho_relacionado}):</h2>
          <div className="desc">
            {sonhoDrawer?.sonho_relacionado}
          </div>
          <img src={sonhoDrawer?.imagem_diario} alt='Sonho' />
          <h3>Diário ({sonhoDrawer?.data}):</h3>
          <div className="desc">
            {sonhoDrawer?.content}
          </div>
        </Box>
      </SwipeableDrawer>
    </>
  );
}

export default SonhosRealizadosPage;
