import React, { useLayoutEffect } from 'react';
import './sonhos-celebres.page.scss';
import { LoadingService } from '../../services/loading.service';
import { GetEventosService } from '../../services/eventos.service';
import { SnackService } from '../../services/snack.service';
import EventosModel from '../../models/eventos.model';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { useHistory } from 'react-router-dom';
import { Box, Button, SwipeableDrawer } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { GetSonhosCelebresService } from '../../services/pages.service';
import SonhosCelebresModel from '../../models/sonhos-celebres.model';

function SonhosCelebresPage() {

  const [listIDs, setListIDs] = React.useState<Array<number>>([]);
  const [listSonhosCelebres, setListSonhosCelebres] = React.useState<Array<SonhosCelebresModel>>([]);
  const [acabouListSonhosCelebres, setAcabouListSonhosCelebres] = React.useState<boolean>(false);
  const [openDrawer, setOpenDrawer] = React.useState<boolean>(false);
  const [sonhoDrawer, setSonhoDrawer] = React.useState<SonhosCelebresModel>();
  let history = useHistory();

  useLayoutEffect(() => {
    setTimeout(() => {
      GetSonhosCelebres();
    }, 500);
  }, []);

  const GetSonhosCelebres = () => {
    LoadingService.sendLoadingAtivo(true);
    GetSonhosCelebresService({ user_id: '7', idsSonhos: listIDs }).then(res => {
      LoadingService.sendLoadingAtivo(false);
      if (res.data.error) {
        SnackService.sendMSG(res.data.error);
      } else {
        setListIDs(listIDs.concat(res.data.idsArr));
        setListSonhosCelebres(listSonhosCelebres.concat(res.data.sonhosArr));
        if (res.data.sonhosArr.length < 5) {
          setAcabouListSonhosCelebres(true);
        }
      }
    }).catch(err => {
      //SnackService.sendMSG('Erro com a conexão ao consultar eventos na base de dados.');
      LoadingService.sendLoadingAtivo(false);
      console.log(err);
    });
  }

  return (
    <>
      <section className="page sonhos-celebres">
        <div className="head">
          <div className="container">
            <div className="flex flex-cv flex-sp">

            </div>
          </div>
        </div>
        <div className="container">
          <div className="cards-sonhos-celebres flex flex-ch flex-wr">
            {
              listSonhosCelebres ? listSonhosCelebres.map((item, indexCurso) => (
                <div onClick={() => {
                  setSonhoDrawer(item);
                  setOpenDrawer(true);
                }} className="card-sonhos-celebres flex" key={indexCurso}>
                  <div className="img" style={{ backgroundImage: 'url(' + item.imagem + ')' }}></div>
                  <div className="txt">
                    <h2>{item.titulo}</h2>
                    <h3>{item.breve_descricao}</h3>
                  </div>
                </div>
              )) : <span>Nenhum item encontrado.</span>
            }
          </div>
          {
            (!acabouListSonhosCelebres && listSonhosCelebres.length > 5) &&
            <div className="box-bt-mais flex flex-ch">
              <Button className='bt cinza' onClick={GetSonhosCelebres}>
                <AddIcon />
                sonhos...
              </Button>
            </div>
          }
        </div>
      </section>
      <SwipeableDrawer
        anchor='bottom'
        open={openDrawer}
        onClose={() => { setOpenDrawer(false) }}
        onOpen={() => { setOpenDrawer(true) }}
        className='sonhos-drawer'
      >
        <Box
          sx={{ height: '90vh', width: '100%' }}
          role="presentation"
        // onClick={() => { setOpenDrawer(false) }}
        //onKeyDown={() => { setOpenDrawer(false) }}
        >
          <img src={sonhoDrawer?.imagem} alt={sonhoDrawer?.titulo} />
          <h2>{sonhoDrawer?.titulo}</h2>
          <div className="desc">
            {sonhoDrawer?.descricao}
          </div>
        </Box>
      </SwipeableDrawer>
    </>
  );
}

export default SonhosCelebresPage;
