import React, { useLayoutEffect } from 'react';
import './ultimos-sonhos.page.scss';
import { LoadingService } from '../../services/loading.service';
import { GetEventosService } from '../../services/eventos.service';
import { SnackService } from '../../services/snack.service';
import EventosModel from '../../models/eventos.model';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { useHistory } from 'react-router-dom';
import { Box, Button, SwipeableDrawer } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { GetSonhosCelebresService, GetUltimosSonhosService } from '../../services/pages.service';
import SonhosCelebresModel from '../../models/sonhos-celebres.model';
import SonhosModel from '../../models/sonhos.model';

function UltimosSonhosPage() {

  const [listIDs, setListIDs] = React.useState<Array<number>>([]);
  const [listUltimosSonhos, setListUltimosSonhos] = React.useState<Array<SonhosModel>>([]);
  const [acabouListUltimosSonhos, setAcabouListUltimosSonhos] = React.useState<boolean>(false);
  const [openDrawer, setOpenDrawer] = React.useState<boolean>(false);
  const [sonhoDrawer, setSonhoDrawer] = React.useState<SonhosModel>();
  let history = useHistory();

  useLayoutEffect(() => {
    setTimeout(() => {
      GetSonhosCelebres();
    }, 500);
  }, []);

  const GetSonhosCelebres = () => {
    LoadingService.sendLoadingAtivo(true);
    GetUltimosSonhosService({ user_id: '7', idsSonhos: listIDs }).then(res => {
      LoadingService.sendLoadingAtivo(false);
      if (res.data.error) {
        SnackService.sendMSG(res.data.error);
      } else {
        setListIDs(listIDs.concat(res.data.idsArr));
        setListUltimosSonhos(listUltimosSonhos.concat(res.data.sonhosArr));
        if (res.data.sonhosArr.length < 5) {
          setAcabouListUltimosSonhos(true);
        }
      }
    }).catch(err => {
      //SnackService.sendMSG('Erro com a conexão ao consultar eventos na base de dados.');
      LoadingService.sendLoadingAtivo(false);
      console.log(err);
    });
  }

  return (
    <>
      <section className="page ultimos-sonhos">
        <div className="head">
          <div className="container">
            <div className="flex flex-cv flex-sp">

            </div>
          </div>
        </div>
        <div className="container">
          <div className="cards-sonhos-celebres flex flex-ch flex-wr">
            {
              listUltimosSonhos ? listUltimosSonhos.map((item, indexCurso) => (
                <div onClick={() => {
                  setSonhoDrawer(item);
                  setOpenDrawer(true);
                }} className="card-sonhos-celebres flex" key={indexCurso}>
                  <div className="txt">
                    <strong>Sonho:</strong> {item.content.substring(0, 70)} {item.content.length >= 70 && '...'}
                  </div>
                </div>
              )) : <span>Nenhum item encontrado.</span>
            }
          </div>
          {
            (!acabouListUltimosSonhos && listUltimosSonhos.length >= 5) &&
            <div className="box-bt-mais flex flex-ch">
              <Button className='bt' onClick={GetSonhosCelebres}>
                <AddIcon />
                sonhos...
              </Button>
            </div>
          }
        </div>
      </section>
      <SwipeableDrawer
        anchor='bottom'
        open={openDrawer}
        onClose={() => { setOpenDrawer(false) }}
        onOpen={() => { setOpenDrawer(true) }}
        className='sonhos-drawer'
      >
        <Box
          sx={{ height: '90vh', width: '100%' }}
          role="presentation"
        >
          <h2>Sonho:</h2>
          <div className="desc">
            {sonhoDrawer?.content}
          </div>
          <h3>Interpretação:</h3>
          <div className="desc">
            {sonhoDrawer?.interpretacao}
          </div>
        </Box>
      </SwipeableDrawer>
    </>
  );
}

export default UltimosSonhosPage;
