import React, { useLayoutEffect } from 'react';
import './sonhos-realizados.component.scss';
import { Box, Button, SwipeableDrawer, Tooltip } from '@mui/material';
import SonhosCelebresModel from '../../models/sonhos-celebres.model';
import { GetSonhosCelebresService, GetSonhosDestaqueService, GetUltimosSonhosService, ListRelacionadosService } from '../../services/pages.service';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import { SnackService } from '../../services/snack.service';
import { useHistory } from 'react-router-dom';
import SonhosModel from '../../models/sonhos.model';
import SonhosRealizadosModel from '../../models/sonhos-realizados.model';
import ComentaiosComponent from '../comentarios/comentarios.component';
import InfoIcon from '@mui/icons-material/Info';
import CancelIcon from '@mui/icons-material/Cancel';

function SonhosRealizadosComponent() {

  const [listSonhos, setListSonhos] = React.useState<Array<SonhosRealizadosModel>>([]);
  const [sonhoDrawer, setSonhoDrawer] = React.useState<SonhosRealizadosModel>();
  const [openDrawer, setOpenDrawer] = React.useState<boolean>(false);
  const [openTooltip, setOpenTooltip] = React.useState<any>(null);
  let history = useHistory();

  useLayoutEffect(() => {
    setTimeout(() => {
      GetSonhos();
    }, 500);
  }, []);

  const GetSonhos = () => {
    ListRelacionadosService({ user_id: '9', idsDiarios: [] }).then(res => {
      if (res.data.error) {
        SnackService.sendMSG(res.data.error);
      } else {
        setListSonhos(listSonhos.concat(res.data.diariosArr));
      }
    }).catch(err => {
      //SnackService.sendMSG('Erro com a conexão ao consultar sonhos na base de dados.');
      console.log(err);
    });
  }

  const GoPage = (page: string) => {
    history?.push({
      pathname: `/${page}`
    })
  }

  return (
    <>
      <div className="box-sonhos-realizados">
        <div className="titulo flex flex-sp flex-cv">
          <h3>Sonhos realizados</h3>
          <Button className='link' onClick={() => GoPage('sonhos-realizados?app=1')}>Ver todos</Button>
        </div>
        <div className="cards-sonhos flex flex-ch">
          {
            listSonhos ? listSonhos.map((item, indexCurso) => (
              <div onClick={() => {
                setSonhoDrawer(item);
                setOpenDrawer(true);
              }} className="card-sonhos flex" key={indexCurso}>
                {
                  item.imagem_sonho &&
                  <div className="img" style={{ backgroundImage: 'url(' + item.imagem_sonho + ')' }}></div>
                }
                <div className="box-ico">
                  <div className="txt">
                    <h4>Sonho: {item.data_sonho_relacionado}</h4>
                    <AllInclusiveIcon />
                    <h4>Diário: {item.data}</h4>
                  </div>
                </div>
                {
                  item.imagem_diario &&
                  <div className="img" style={{ backgroundImage: 'url(' + item.imagem_diario + ')' }}></div>
                }
              </div>
            )) : <span>Nenhum item encontrado.</span>
          }
        </div>
      </div>
      <SwipeableDrawer
        anchor='bottom'
        open={openDrawer}
        onClose={() => { setOpenDrawer(false) }}
        onOpen={() => { setOpenDrawer(true) }}
        className='sonhos-drawer'
      >
        <Box
          sx={{ height: '90vh', width: '100%' }}
          role="presentation"
        // onClick={() => { setOpenDrawer(false) }}
        //onKeyDown={() => { setOpenDrawer(false) }}
        >
          {
            sonhoDrawer &&
            <>
              <img src={sonhoDrawer?.imagem_sonho} alt='Sonho' />
              <h2>Sonho ({sonhoDrawer?.data_sonho_relacionado}):</h2>
              {
                (sonhoDrawer?.data_atualizacao_sonho_relacionado !== sonhoDrawer?.data_sonho_relacionado) &&
                <div className='data-update'>
                  <Tooltip
                    PopperProps={{
                      disablePortal: true,
                    }}
                    placement="top"
                    onClose={() => setOpenTooltip(null)}
                    open={openTooltip === 0 ? true : false}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    title="Esse sonho não foi registrado na data em que ocorreu, pois a data foi inserida manualmente. Quando os sonhos são inseridos manualmente, há o risco de perderem credibilidade, uma vez que os usuários podem inadvertidamente relacioná-los a eventos significativos ocorridos após o sonho. Por exemplo, se algo marcante, como a queda de um meteoro em uma cidade do interior que resultou na morte de três vacas, aconteceu e o usuário decide inserir um sonho três semanas anteriores mencionando o meteoro, isso pode comprometer a veracidade do registro. Portanto, é fundamental lembrar-se de registrar seus sonhos na mesma data em que ocorreram, pois isso contribui significativamente para a credibilidade das experiências relatadas."
                  >
                    <Button className='bt-info' onClick={() => {
                      openTooltip === 0 ?
                        setOpenTooltip(null)
                        :
                        setOpenTooltip(0)
                    }}>
                      {
                        openTooltip === 0 ?
                          <CancelIcon />
                          :
                          <InfoIcon />
                      }
                    </Button>
                  </Tooltip>
                  <small>
                    {sonhoDrawer?.data_atualizacao_sonho_relacionado} (Data atualização)
                  </small>
                </div>
              }
              <div className="desc">
                {sonhoDrawer?.sonho_relacionado}
              </div>
              <img src={sonhoDrawer?.imagem_diario} alt='Sonho' />
              <h3>Diário ({sonhoDrawer?.data}):</h3>
              <div className="desc">
                {sonhoDrawer?.content}
              </div>
              <div className="box-comentarios">
                <ComentaiosComponent idPost={sonhoDrawer.id} palavrasModeradasADM={['bobo', 'bobão']} />
              </div>
            </>
          }
        </Box>
      </SwipeableDrawer>
    </>
  );
}

export default SonhosRealizadosComponent;
