import axios from "axios";
import { environment } from "../environments/environment";

const config: any = {
    //headers: {}
};

export const api = axios.create({
    baseURL: environment.serverUrl
});

export async function GetEventosService(dto: { user_email: string, idsEventos: number[] }) {
    let url = '/get-eventos';
    return await api.post(url, dto, config);
}

export async function EventoService(dto: { alias: string }) {
    let url = '/get-evento';
    return await api.post(url, dto, config);
}

export async function ListDiariosService(dto: { user_id: string, idsDiarios: number[] }) {
    let url = '/list-diarios';
    return await api.post(url, dto, config);
}

export async function ListSonhosService(dto: { user_id: string, idsSonhos: number[] }) {
    let url = '/list-sonhos';
    return await api.post(url, dto, config);
}

export async function BuscaSonhosService(dto: { user_id: string, termo: string }) {
    let url = '/busca-sonhos';
    return await api.post(url, dto, config);
}

export async function RelacionarService(dto: {
    post_id: number,
    sonho_id: number,
    data_sonho_relacionado: string,
    data_atualizacao_sonho_relacionado: string,
    sonho_relacionado: string
}) {
    let url = '/relacionar-sonho';
    return await api.post(url, dto, config);
}

export async function AtualizaSonhosService(dto: { post_id_us: number, post_date: string }) {
    let url = '/atualiza-sonho';
    return await api.post(url, dto, config);
}

export async function AtualizaDiarioService(dto: { post_id_us: number, post_date: string }) {
    let url = '/atualiza-diario';
    return await api.post(url, dto, config);
}

export async function GetNoticiasService(dto: { user_email: string, idsNoticias: number[] }) {
    let url = '/get-noticias';
    return await api.post(url, dto, config);
}
