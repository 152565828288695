import React from 'react';
import { Button, CircularProgress, InputAdornment, TextField } from '@mui/material';
import './esqueci-senha.component.scss';
import UsuarioModel from '../../models/usuario.model';
import { SnackService } from '../../services/snack.service';
import { LoadingService } from '../../services/loading.service';
import { CadastrarService, EsqueciSenhaService, VerificaEmailService } from '../../services/api-usuario.service';
import { useInput } from '../../hooks/useInput.hook';
import { AccountCircle } from '@mui/icons-material';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import Box from '@mui/material/Box';

type EsqueciSenhaComponentProps = {
  goToComponent: (goToComponent: string) => void;
  primeiroAcesso: (primeiroAcesso: string) => void;
}

function EsqueciSenhaComponent(props: EsqueciSenhaComponentProps) {

  const { value: emailInputText, bind: bindEmailInputText, setValue: setEmailInputText } = useInput('');
  const { value: nomeCompletoInputText, bind: bindNomeCompletoInputText, setValue: setNomeCompletoInputText } = useInput('');
  const { value: dealerInputText, bind: bindDealerInputText, setValue: setDealerInputText } = useInput('');
  const { value: cidadeInputText, bind: bindCidadeInputText, setValue: setCidadeInputText } = useInput('');
  const { value: estadoInputText, bind: bindEstadoInputText, setValue: setEstadoInputText } = useInput('');
  const [showLoading, setShowLoading] = React.useState<boolean>(false);

  var dto: any;

  const Cadastrar = (e: any) => {
    e.stopPropagation();

    if (!emailInputText) {
      SnackService.sendMSG('O campo e-mail é obrigatório');
      return;
    } else if (emailInputText.indexOf('@') == -1 || emailInputText.indexOf('.') == -1) {
      SnackService.sendMSG('Digite um e-mail válido');
      return;
    }

    CadastrarAposVerificarEmail();
    
  }

  const CadastrarAposVerificarEmail = () => {
    dto = {
      user_email: emailInputText
    };

    LoadingService.sendLoadingAtivo(true);

    EsqueciSenhaService(dto).then((res) => {
      LoadingService.sendLoadingAtivo(false);
      if (res.data?.error) {
        SnackService.sendMSG(res.data?.error);
      } else {
        if (res?.data?.email_exists) {
          SnackService.sendMSG(res?.data?.email_exists);
          LimparForm();
          setShowLoading(false);
          props.primeiroAcesso(emailInputText);
          props.goToComponent('login');
        } else if (res?.data?.email_no_exists) {
          SnackService.sendMSG(res?.data?.email_no_exists);
          LimparForm();
          setShowLoading(false);
          props.primeiroAcesso(emailInputText);
          props.goToComponent('cadastro');
        }
      }
    }).catch(err => {
      LoadingService.sendLoadingAtivo(false);
      SnackService.sendMSG('Erro com a conexão.');
      console.log(err);
    });
  }

  const LimparForm = () => {
    setEmailInputText('');
  }

  return (
    <div className="esqueci-senha-component">
      <h3>INFORME SEU E-MAIL PARA RECEBER UMA NOVA SENHA.</h3>
      <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
        <MailOutlineIcon />
        <TextField {...bindEmailInputText} label="E-mail" variant="standard" />
      </Box>
      <Button onClick={Cadastrar} className={"bt " + 'loading-' + showLoading}>
        <CircularProgress />
        Receber nova senha
      </Button>
      <div className="flex flex-ch">
        <Button onClick={() => {
          props.primeiroAcesso('');
          props.goToComponent('login');
        }} className="link">Entrar</Button>
        <Button onClick={() => {
          props.primeiroAcesso('');
          props.goToComponent('cadastro');
        }} className="link">Cadastrar</Button>
      </div>
    </div>
  );
}

export default EsqueciSenhaComponent;
