import React, { useEffect, useState } from 'react';
import './sonho.page.scss';
import OpenAIApi from 'openai';
import ChatCompletionRequestMessageRoleEnum from 'openai';
import MensagensChatModel from '../../models/mensagens.model';
import { Button, Tooltip } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useHistory } from 'react-router-dom';
import imgiDream from '../../assets/header/logo-idream.svg';
import imgUsuario from '../../assets/sonho/img-usuario.png';
import imgSend from '../../assets/sonho/send.svg';
import { LoadingService } from '../../services/loading.service';
import { GetSonhosService, SalvaChatService } from '../../services/pages.service';
import UsuarioModel from '../../models/usuario.model';
import { getAuthUser } from '../../services/auth';
import InfoIcon from '@mui/icons-material/Info';
import CancelIcon from '@mui/icons-material/Cancel';
import { SnackService } from '../../services/snack.service';
//import OpenAIApi from 'openai';
//const { Configuration, OpenAIApi } = require("openai");


function SonhoPage() {
  const openai = new OpenAIApi({
    apiKey: "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",//process.env.REACT_APP_OPENAI_API_KEY,
    dangerouslyAllowBrowser: true
  });
  const [prompt, setPrompt] = useState("");
  const [apiResponse, setApiResponse] = useState("");
  const [loading, setLoading] = useState(false);
  const [usuarioLogado, setUsuarioLogado] = React.useState<UsuarioModel>(getAuthUser());
  const [openTooltip, setOpenTooltip] = React.useState(false);

  const [mensagens, setMensagens] = useState<MensagensChatModel[]>([]);
  let history = useHistory();

  useEffect(() => {
    GetSonhos();
    console.log(usuarioLogado.ID, 'usuarioLogado.ID');
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (mensagens.length >= 4) {
      SnackService.sendMSG('Limite de sonho atingido. Registre novos sonhos amanhã =)');
      return;
    }

    setLoading(true);
    let msgUser = prompt;
    setMensagens(current => [...current, { content: msgUser, role: 'user' }]);
    SalvaChat({ content: msgUser, role: 'user', loading: true, idNewPost: '' });
  };

  const salvaMsgSystem = async (idNewPost: string) => {
    let msgUser = prompt;
    try {
      const result: any = await openai.chat.completions.create({
        messages: [
          {
            role: 'system',
            //stream: true,
            content: !!msgUser ? 'O que significa sonhar com ' + msgUser : 'Hello',
          }
        ],
        model: 'gpt-3.5-turbo',
      });
      setMensagens(current => [...current, result.choices[0].message]);
      setApiResponse(result.choices[0].message.content);
      setPrompt('');
      setLoading(false);
      SalvaChat({ content: result.choices[0].message.content, role: 'system', loading: false, idNewPost: idNewPost });

    } catch (e) {
      console.log(e);
      setApiResponse("Something is going wrong, Please try again.");
      //LoadingService.sendLoadingAtivo(false);
      setLoading(false);
    }
  }

  const SalvaChat = async (objChat: { content: string, role: string, loading: boolean, idNewPost: string }) => {
    //LoadingService.sendLoadingAtivo(true);
    let dto = {
      user_id: objChat.role === 'system' ? objChat.idNewPost : usuarioLogado.ID,
      role: objChat.role,
      content: objChat.content
    }
    SalvaChatService(dto).then(res => {
      //LoadingService.sendLoadingAtivo(false);
      if (res.data.error) {
        //SnackService.sendMSG(res.data.error);
      } else {
        if (objChat.role === 'user') {
          if (res.data.post_id == '0') {
            SnackService.sendMSG('Erro ao registrar sonho, copie seu texto, guarde num bloco de notas e tente novamente mais tarde.');
            setPrompt('');
            setLoading(false);
            setMensagens(current => [...current, { content: 'Tente novamente mais tarde.', role: 'system' }]);
          } else {
            salvaMsgSystem(res.data.post_id);
          }
        }
      }
      //setLoading(loading);
    }).catch(err => {
      //SnackService.sendMSG('Erro com a conexão ao carregar página.');
      //setLoading(loading);
      console.log(err);
    });
  }

  const GetSonhos = () => {
    LoadingService.sendLoadingAtivo(true);
    let dto = {
      user_id: usuarioLogado.ID
    }
    GetSonhosService(dto).then(res => {
      LoadingService.sendLoadingAtivo(false);
      if (res.data.error) {
        SnackService.sendMSG(res.data.error);
      } else {
        //setDestaque(res.data);
        setMensagens(res.data.reverse());
      }
    }).catch(err => {
      SnackService.sendMSG('Erro com a conexão ao carregar página.');
      LoadingService.sendLoadingAtivo(false);
      console.log(err);
    });
  }

  const GoPage = (page: string) => {
    history?.push({
      pathname: `/${page}`
    })
  }

  return (
    <section className="sonho page">
      <div className="container">
        <h1 className='flex flex-cv flex-sp'>
          <div className="esq flex flex-cv">
            <Button className='bt-seta' onClick={() => { history.goBack() }}>
              <ArrowBackIosIcon />
            </Button>
            Registro de sonhos
          </div>
          <Button className='bt' onClick={() => GoPage('sonhos?app=1')}>Ver tudo</Button>
        </h1>
      </div>
      <div className="msgs">
        <div className="container">
          {
            mensagens.map((item, indexMsg) => (
              // <div key={indexMsg} className="box-msg">
              //   <h2>{item.role}</h2>
              //   <p>{item.content}</p>
              // </div>
              <>
                {
                  item.role == 'user' ?
                    <div key={indexMsg} className="box-msg usuario flex flex-bv flex-ri">
                      <div className="msg">
                        {item.content}
                      </div>
                      {
                        usuarioLogado ?
                          usuarioLogado.foto?.toString().indexOf('http') > -1 ?
                            <img className='foto' src={usuarioLogado.foto} alt="Profile" />
                            :
                            <img src={imgUsuario} alt="Profile" />
                          :
                          <img src={imgUsuario} alt="Profile" />
                      }
                    </div>
                    :
                    <div key={indexMsg} className="box-msg idream flex flex-bv">
                      <img src={imgiDream} alt="iDream" />
                      <div className="msg">
                        {item.content}
                      </div>
                    </div>
                }
              </>
            ))
          }

          {
            loading &&
            <div className="box-msg idream flex flex-bv">
              <img src={imgiDream} alt="iDream" />
              <div className="msg loading flex flex-cv">
                Digitando
                <i dangerouslySetInnerHTML={{ __html: '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid"> <circle cx="27.5" cy="57.5" r="5" fill="#528aea"> <animate attributeName="cy" calcMode="spline" keySplines="0 0.5 0.5 1;0.5 0 1 0.5;0.5 0.5 0.5 0.5" repeatCount="indefinite" values="57.5;42.5;57.5;57.5" keyTimes="0;0.3;0.6;1" dur="1s" begin="-0.6s"></animate> </circle> <circle cx="42.5" cy="57.5" r="5" fill="#528aea"> <animate attributeName="cy" calcMode="spline" keySplines="0 0.5 0.5 1;0.5 0 1 0.5;0.5 0.5 0.5 0.5" repeatCount="indefinite" values="57.5;42.5;57.5;57.5" keyTimes="0;0.3;0.6;1" dur="1s" begin="-0.44999999999999996s"></animate> </circle> <circle cx="57.5" cy="57.5" r="5" fill="#528aea"> <animate attributeName="cy" calcMode="spline" keySplines="0 0.5 0.5 1;0.5 0 1 0.5;0.5 0.5 0.5 0.5" repeatCount="indefinite" values="57.5;42.5;57.5;57.5" keyTimes="0;0.3;0.6;1" dur="1s" begin="-0.3s"></animate> </circle> <circle cx="72.5" cy="57.5" r="5" fill="#528aea"> <animate attributeName="cy" calcMode="spline" keySplines="0 0.5 0.5 1;0.5 0 1 0.5;0.5 0.5 0.5 0.5" repeatCount="indefinite" values="57.5;42.5;57.5;57.5" keyTimes="0;0.3;0.6;1" dur="1s" begin="-0.15s"></animate> </circle> </svg>' }}></i>
              </div>
            </div>
          }
        </div>
      </div>
      <form onSubmit={handleSubmit} >
        <div className="container">
          <div className="flex flex-cv">
            <Tooltip
              PopperProps={{
                disablePortal: true,
              }}
              placement="top"
              onClose={() => setOpenTooltip(false)}
              open={openTooltip}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              title="Você tem permissão para compartilhar até dois sonhos diariamente (caso tenha cochilado à tarde). Por favor, compartilhe apenas o sonho de hoje."
            >
              <Button className='bt-info' onClick={() => setOpenTooltip(!openTooltip)}>
                {
                  openTooltip ?
                    <CancelIcon />
                    :
                    <InfoIcon />
                }
              </Button>
            </Tooltip>
            <textarea
              value={prompt}
              rows={1}
              placeholder="Digite seu sonho aqui"
              onChange={(e) => setPrompt(e.target.value)}
            ></textarea>
            <Button
              disabled={loading || prompt.length === 0}
              type='submit'>
              <img src={imgSend} alt="Enviar" />
            </Button>
          </div>
        </div>
      </form>
    </section>
  );
}

export default SonhoPage;
