import React, { useEffect, useState } from 'react';
import './aconteceu.page.scss';
import OpenAIApi from 'openai';
import ChatCompletionRequestMessageRoleEnum from 'openai';
import MensagensChatModel from '../../models/mensagens.model';
import { Button, CircularProgress, Tooltip } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useHistory } from 'react-router-dom';
import imgiDream from '../../assets/header/logo-idream.svg';
import imgUsuario from '../../assets/sonho/img-usuario.png';
import imgSend from '../../assets/sonho/send.svg';
import { LoadingService } from '../../services/loading.service';
import { GetDiarioService, SalvaChatService, SalvaDiarioService } from '../../services/pages.service';
import UsuarioModel from '../../models/usuario.model';
import { getAuthUser } from '../../services/auth';
import InfoIcon from '@mui/icons-material/Info';
import CancelIcon from '@mui/icons-material/Cancel';
import DiarioModel from '../../models/diario.model';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import { SnackService } from '../../services/snack.service';
//import OpenAIApi from 'openai';
//const { Configuration, OpenAIApi } = require("openai");


function AconteceuPage() {


  const [prompt, setPrompt] = useState("");
  const [loading, setLoading] = useState(false);
  const [usuarioLogado, setUsuarioLogado] = React.useState<UsuarioModel>(getAuthUser());
  const [openTooltip, setOpenTooltip] = React.useState(false);
  const [editando, setEditando] = React.useState(false);
  const [diarioList, setDiarioList] = useState<DiarioModel[]>();

  let history = useHistory();

  useEffect(() => {
    //let newList = diarioList.reverse();
    //setDiarioList(newList);
    GetDiario();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    SalvaChat({ content: prompt, loading: true });
  };

  const SalvaChat = (objChat: { content: string, loading: boolean }) => {
    //LoadingService.sendLoadingAtivo(true);
    if(objChat.content === 'Nenhum registro foi feito no diário hoje.'){
      SnackService.sendMSG('Digite algo para salvar.');
      setLoading(false);
      return;
    }

    let dto = {
      user_id: usuarioLogado.ID,
      content: objChat.content
    }
    SalvaDiarioService(dto).then(res => {
      //LoadingService.sendLoadingAtivo(false);
      if (res.data.error) {
        //SnackService.sendMSG(res.data.error);
      } else {
        //setDestaque(res.data);
        if (res.data === 'sucesso' && diarioList) {
          // let newList = diarioList;
          // newList[0].content = objChat.content;
          // newList = newList.reverse();
          // setDiarioList(newList);
          GetDiario();
        }
      }
      setLoading(false);
      setEditando(false);
      //setLoading(loading);
    }).catch(err => {
      //SnackService.sendMSG('Erro com a conexão ao carregar página.');
      setLoading(false);
      console.log(err);
    });
  }

  const GetDiario = () => {
    LoadingService.sendLoadingAtivo(true);
    let dto = {
      user_id: usuarioLogado.ID
    }
    GetDiarioService(dto).then(res => {
      LoadingService.sendLoadingAtivo(false);
      if (res.data.error) {
        SnackService.sendMSG(res.data.error);
      } else {
        //setDestaque(res.data);
        let newList = res.data;
        setPrompt(newList[0].content.replaceAll('<br />', ''));
        newList = newList.reverse();
        setDiarioList(newList);
      }
      setLoading(false);
      setEditando(false);
      //setLoading(loading);
    }).catch(err => {
      SnackService.sendMSG('Erro com a conexão ao carregar página.');
      setLoading(false);
      console.log(err);
    });
  }

  const GoPage = (page: string) => {
    history?.push({
      pathname: `/${page}`
    })
  }

  return (
    <section className="aconteceu page">
      <div className="container">
        <h1 className='flex flex-cv flex-sp'>
          <div className="esq flex flex-cv">
            <Button className='bt-seta' onClick={() => { history.goBack() }}>
              <ArrowBackIosIcon />
            </Button>
            Diário
          </div>
          <Button className='bt' onClick={() => GoPage('diarios?app=1')}>Ver tudo</Button>
        </h1>

        <div className="folhas">
          {
            diarioList?.map((item, indexDiario) => (
              <div key={indexDiario} className="folha">
                <h3>{item.data}</h3>
                <div className="linhas-scroll">
                  <ol>
                    {
                      indexDiario === 2 ?
                        (!editando) ?
                          <li className="txt" dangerouslySetInnerHTML={{ __html: item.content }}></li>
                          :
                          <li>
                            <form onSubmit={handleSubmit} >
                              <div className="flex flex-cv">
                                <textarea
                                  value={prompt === 'Nenhum registro foi feito no diário hoje.' ? '' : prompt}
                                  rows={1}
                                  placeholder="Digite seu acontecimento do dia aqui"
                                  onChange={(e) => setPrompt(e.target.value)}
                                ></textarea>
                              </div>
                            </form>
                          </li>
                        :
                        <li className="txt" dangerouslySetInnerHTML={{ __html: item.content }}></li>
                    }
                  </ol>
                </div>
                {
                  indexDiario === 2 &&
                  <div className="foot flex flex-ri">
                    {
                      loading &&
                      <CircularProgress />
                    }
                    {
                      editando &&
                      <Button onClick={handleSubmit} disabled={loading || prompt.length === 0}>
                        <SaveIcon />
                      </Button>
                    }
                    <Button onClick={() => setEditando(!editando)} disabled={loading}>
                      {
                        editando ?
                          <CancelIcon />
                          :
                          <EditIcon />
                      }
                    </Button>
                  </div>
                }
              </div>
            ))
          }
        </div>
      </div>
    </section>
  );
}

export default AconteceuPage;
