import React, { useEffect } from 'react';
import './login.page.scss';
import CadastroComponent from '../../components/cadastro/cadastro.component';
import idreamAzul from '../../assets/header/idream-azul.svg';
import logoiDream from '../../assets/header/logo-idream.svg';
import LoginComponent from '../../components/login/login.component';
import { useHistory } from 'react-router-dom';
import EsqueciSenhaComponent from '../../components/esqueci-senha/esqueci-senha.component';

function LoginPage() {
  const [goToComponent, setGoToComponent] = React.useState<string>('cadastro');
  const [primeiroAcesso, setPrimeiroAcesso] = React.useState<string>('');
  const [nBG, setNBG] = React.useState<number>(1);
  let usLogado = localStorage.getItem("user");
  let history = useHistory();

  useEffect(() => {
    document.body.classList.add('is-login-page');
    if (usLogado) {
      AcessarHome();
    }

    setNBG(Math.floor(Math.random() * (7 - 2 + 1) + 2));

    return function () {
      document.body.classList.remove('is-login-page');
    };
  }, []);

  const AcessarHome = () => {
    history?.push({
      pathname: `/home?app=1`
    })
  }

  return (
    <section className={"login-page flex flex-bv tenho-conta-" + goToComponent + " n-" + nBG}>
      <div className="container">
        <div className='box-logo'>
          <p>
            <img src={logoiDream} alt="Logo iDream" />
          </p>
          <img src={idreamAzul} alt="Logo iDream" />
        </div>
        <CadastroComponent
          goToComponent={(goToComponent) => { setGoToComponent(goToComponent) }}
          primeiroAcesso={(primeiroAcesso) => { setPrimeiroAcesso(primeiroAcesso) }}
        />
        <LoginComponent
          goToComponent={(goToComponent) => { setGoToComponent(goToComponent) }}
          email={primeiroAcesso}
        />
        <EsqueciSenhaComponent
          goToComponent={(goToComponent) => { setGoToComponent(goToComponent) }}
          primeiroAcesso={(primeiroAcesso) => { setPrimeiroAcesso(primeiroAcesso) }}
        />
      </div>
    </section>
  );
}

export default LoginPage;
